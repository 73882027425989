import React from "react";
import Track from "./components/Track";
import { StemGrid } from "./components/StemGrid";
import "./index.css";
import { CustomizeSection } from "./components/CustomizeSection";
import { calculateSemitoneShift } from "./components/Key/KeyFunctions";

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      musicalKey: "E",
      minor: false,
      BPM: 105,
      semiToneAmount: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleSpacebar);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleSpacebar);
  }

  handleSpacebar = (event) => {
    if (event.code === "Space") {
      event.preventDefault();
      this.togglePlay();
    }
  };

  togglePlay = () => {
    var current = this.state.isPlaying;
    this.setState({ isPlaying: !current });
  };

  handleKeyClick = (key, minor) => {
    const semiToneAmount = calculateSemitoneShift(
      this.state.musicalKey,
      this.state.minor,
      key,
      minor
    );
    this.setState(
      { musicalKey: key, minor: minor, semiToneAmount: semiToneAmount },
      () => {
        this.forceUpdate();
      }
    );
  };

  handleBPMChange = (bpm) => {
    console.log(`changing bpm from ${this.state.BPM} to ${bpm}`);
    this.setState({ BPM: bpm });
  };

  render() {
    return (
      <div className="div-stemplayer2">
        <div className="page">
          <div className="main">
            <Track
              isPlaying={this.state.isPlaying}
              togglePlay={this.togglePlay}
              audio={"/assets/audio/beachbum.mp3"}
            />
            <CustomizeSection
              musicalKey={this.state.musicalKey}
              minor={this.state.minor}
              bpm={this.state.BPM}
              handleKeyClick={this.handleKeyClick}
              handleBPMChange={this.handleBPMChange}
            />
            <StemGrid
              isPlaying={this.state.isPlaying}
              musicalKey={this.state.musicalKey}
              minor={this.state.minor}
              bpm={this.state.BPM}
              semiToneAmount={this.state.semiToneAmount}
              mute={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AudioPlayer;
