export const topKeys = ["C#/Db", "D#/Eb", "F#/Gb", "G#/Ab", "A#/Bb"];
export const bottomKeys = ["C", "D", "E", "F", "G", "A", "B"];
export const keyDict = {
  C: -9,
  "C#/Db": -8,
  D: -7,
  "D#/Eb": -6,
  E: -5,
  F: -4,
  "F#/Gb": -3,
  G: -2,
  "G#/Ab": -1,
  A: 0,
  "A#/Bb": 1,
  B: 2,
};
