import "../../css/BPM.scss";
import React, { useState } from "react";
import { DataList, BPMInputSection } from "./BPMLabels";

export function Slider({ bpm, handleBPMChange }) {
  const [value, setValue] = useState(bpm);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleMouseUp = () => {
    handleBPMChange(value);
  };

  const handleArrowClick = (increment) => {
    const newValue = value + increment;
    setValue(Math.max(0, newValue));
  };

  return (
    <div className="speed-section">
      <BPMInputSection
        value={value}
        handleChange={handleChange}
        handleArrowClick={handleArrowClick}
        handleMouseUp={handleMouseUp}
      />
      {/* </div> */}
      <div className="speed-slider">
        <DataList
          value={value}
          handleChange={handleChange}
          handleMouseUp={handleMouseUp}
        />
      </div>
    </div>
  );
}
