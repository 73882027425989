import React, { useState } from "react";
import "../../css/ToggleKey.scss";

export default function ToggleKey({ minor, onToggle }) {
  const [isMinor, setIsMinor] = useState(minor);

  const handleClick = () => {
    const newValue = !isMinor;
    setIsMinor(newValue);
    onToggle(newValue);
  };

  return (
    <div className="toggle-button-container">
      <div className="minor toggle-button-label">Minor</div>
      <div className="major toggle-button-label">Major</div>
      <div className="span-mui-switch-root">
        <div className="toggle-button-row">
          <div className="toggle-button-knob" />
          <div
            className={`toggle-button-switch ${isMinor ? "minor" : "major"}`}
          />
          <div className="toggle-button" onClick={handleClick}></div>
        </div>
      </div>
    </div>
  );
}
