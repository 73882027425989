import React from "react";
import "../../css/Stem.scss";
import { CustomTextLg, CustomTextM } from "../CustomButtons";
import { FaForward, FaBackward } from "react-icons/fa";
import { Knob } from "react-rotary-knob";
import * as skins from "react-rotary-knob-skin-pack";

export function StemLabel(props) {
  const audioLabel = props.audio[props.currentAudioIndex].label;

  return (
    <div className="stem-label">
      <div className="stem-icon-container">
        <img className="stem-icon" src={props.icon} alt={props.label} />
      </div>
      <div className="stem-label-row">
        {CustomTextLg(props.label)}
        <div className="subheading-row">
          <button
            className={"big-button outer-left"}
            onClick={props.handleSkipBackward}
          >
            <FaBackward />
          </button>
          {CustomTextM(audioLabel)}
          <button
            className={"big-button outer-right"}
            onClick={props.handleSkipForward}
          >
            <FaForward />
          </button>
        </div>
      </div>

      <div className="stem-controls">
        <Knob
          skin={skins.s10}
          min={-12}
          max={12}
          value={parseFloat(props.volume)}
          onChange={props.handleVolumeChange}
          preciseMode={false}
        />
        <div className="button-group">
          <button
            className={
              props.muted ? "button active outer-left" : "button outer-left"
            }
            onClick={props.toggleMute}
          >
            M
          </button>
          <button
            className={
              props.solo ? "button active outer-right" : "button outer-right"
            }
            onClick={() => props.toggleSolo(true)}
          >
            S
          </button>
        </div>
      </div>
    </div>
  );
}
