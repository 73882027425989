import { updatePlaybackSettings } from "./PlaybackFunctions";
import { WaveSurferCreator } from "../WaveForm/WaveForm";

import * as Tone from "tone";

export function handleInitialLoad(state, waveformRef) {
  // Initialize waveform
  const waveform = WaveSurferCreator(waveformRef.current);
  waveform.load(state.audioFile);
  waveform.setMute(true);
  waveform.on("finish", () => {
    waveform.play(0);
  });

  // Initialize player
  const player = new Tone.GrainPlayer({
    url: state.audioFile,
    loop: true,
    grainSize: 0.1,
    overlap: 0.05,
  }).toDestination();

  // Initialize pitchShift
  const pitchShift = new Tone.PitchShift(0).toDestination();

  // Update playback settings
  updatePlaybackSettings(state, pitchShift, player, waveform);

  return { waveform, player, pitchShift };
}

export function disposeComponents(pitchShift, player, waveform) {
  pitchShift?.dispose();
  player?.stop().dispose();
  waveform?.destroy();
}
