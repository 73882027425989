import React, { useState } from "react";
import "../css/Stem.scss";
import stemData from "../data/StemData";
import TransposableStem from "./Stem/TransposableStem";
import "../index.css";

export function StemGrid(props) {
  const { musicalKey, minor, bpm, isPlaying, semiToneAmount } = props;
  const stemParts = stemData;
  const initialMutedStems = {};
  stemData.forEach((stem) => {
    initialMutedStems[stem.label] = false;
  });
  const [mutedStems, setMutedStems] = useState(initialMutedStems);

  function handleMuteOthers(id, isSolo) {
    const newMutedStems = { ...mutedStems };
    stemData.forEach((stem) => {
      if (stem.label !== id) {
        newMutedStems[stem.label] = isSolo;
      }
    });
    setMutedStems(newMutedStems);
  }

  return (
    <div className="divbg-black">
      <div className="divflex-3">
        {stemParts.map((stem, index) => (
          <TransposableStem
            key={index}
            label={stem.label}
            icon={stem.icon}
            audio={stem.audio}
            isPlaying={isPlaying}
            musicalKey={musicalKey}
            minor={minor}
            bpm={bpm}
            semiToneAmount={semiToneAmount}
            volume="0"
            solo={stemParts[index].solo}
            muted={mutedStems[stem.label]}
            muteOthers={handleMuteOthers}
          />
        ))}
      </div>
    </div>
  );
}
