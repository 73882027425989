// import * as Tone from "tone";

// export function updateBPM(oldBPM, newBPM, player, waveform) {
//   const playbackRate = (newBPM / oldBPM) * player.playbackRate;
//   player.playbackRate = playbackRate;
//   Tone.Transport.bpm.value = newBPM;
//   waveform.setPlaybackRate(playbackRate);
// }
export function updateBPM(oldBPM, newBPM, player, waveform) {
  const playbackRate = newBPM / oldBPM;
  player.playbackRate = playbackRate;
  waveform.setPlaybackRate(playbackRate);
}
