export const bassData = [
  {
    label: "Bass 1",
    audio: "/assets/audio/metallic.mp3",
    bpm: 83,
    key: "C",
    minor: false,
  },
  {
    label: "Bass 2",
    audio: "/assets/audio/rise.mp3",
    bpm: 95,
    key: "C",
    minor: false,
  },
  // add more stems here as needed
];
