import React from "react";
import * as Tone from "tone";
import { setPitchShift, togglePlay } from "../Key/KeyFunctions";
import { handleInitialLoad, disposeComponents } from "./StemLoad";
import { updatePlaybackSettings } from "./PlaybackFunctions";
import { handleSkip } from "./StemFunctions";
import { updateBPM } from "../BPM/BPMFunctions";
import { StemLabel } from "./StemLabel";

export default class TransposableStem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      loaded: false,
      muted: props.muted,
      solo: props.solo,
      interactedWithPage: false,
      musicalKey: props.musicalKey,
      minor: props.minor,
      bpm: props.bpm,
      currentAudioIndex: 0,
      audioFile: props.audio[0].audio,
      audioLabel: props.audio[0].label,
      originalBPM: props.audio[0].bpm,
      originalKey: props.audio[0].key,
      originalMinor: props.audio[0].minor,
      volume: props.volume || 0.5,
    };
    this.waveformRef = React.createRef();
    this.waveform = null;
    this.player = null;
    this.pitchShift = null;
    this.audioContext = null;
  }

  componentDidMount() {
    const { waveform, player, pitchShift } = handleInitialLoad(
      this.state,
      this.waveformRef
    );
    this.waveform = waveform;
    this.player = player;
    this.pitchShift = pitchShift;

    this.waveform.on("ready", () => {
      this.setState({ loaded: true });
      this.props.startStem(this.player, this.props.label);
    });
    updateBPM(
      this.state.originalBPM,
      this.props.bpm,
      this.player,
      this.waveform,
      this.pitchShift
    );
    document.addEventListener("click", this.handleUserGesture);
  }

  handleUserGesture = () => {
    if (!this.state.interactedWithPage) {
      this.audioContext = new AudioContext();
      Tone.setContext(this.audioContext);
      this.audioContext.resume();
      this.setState({ interactedWithPage: true });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevState.interactedWithPage &&
      (!this.audioContext || this.audioContext.state !== "running")
    ) {
      this.audioContext = new AudioContext();
      Tone.setContext(this.audioContext);
      this.audioContext.resume();
      this.setState({ interactedWithPage: true });
    }
    if (prevProps.isPlaying !== this.props.isPlaying) {
      togglePlay(
        this.state.isPlaying,
        (isPlaying) => this.setState({ isPlaying }),
        this.state.loaded,
        this.player,
        this.waveform,
        this.audioContext,
        this.state.audioFile,
        this.pitchShift
      );
    }

    if (prevProps.semiToneAmount !== this.props.semiToneAmount) {
      setPitchShift(this.props.semiToneAmount, this.player, this.pitchShift);
      this.setState({
        musicalKey: this.props.musicalKey,
        minor: this.props.minor,
      });
    }
    if (prevProps.bpm !== this.props.bpm) {
      updateBPM(prevState.bpm, this.props.bpm, this.player, this.waveform);
      this.setState({ bpm: this.props.bpm });
    }
    if (prevState.muted !== this.props.muted) {
      this.player.mute = this.props.muted;
      this.setState({ muted: this.props.muted });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleUserGesture);
    disposeComponents(this.pitchShift, this.player, this.waveform);
  }

  toggleMute = () => {
    const newMuted = !this.state.muted;
    this.player.mute = newMuted;
    this.setState({ muted: newMuted });
  };

  toggleSolo = () => {
    const currentStatus = this.state.solo;
    this.props.muteOthers(this.props.label, !currentStatus);
    this.setState({ solo: !currentStatus });
  };

  handleSkipForward = () => {
    handleSkip(
      1,
      this.state,
      this.props,
      this.player,
      this.waveform,
      (newState) => this.setState(newState)
    );
    updatePlaybackSettings(
      this.state,
      this.pitchShift,
      this.player,
      this.waveform
    );
  };

  handleSkipBackward = () => {
    handleSkip(
      -1,
      this.state,
      this.props,
      this.player,
      this.waveform,
      (newState) => this.setState(newState)
    );
    updatePlaybackSettings(
      this.state,
      this.pitchShift,
      this.player,
      this.waveform
    );
  };

  handleVolumeChange = (newValue) => {
    const volume = parseFloat(newValue);
    this.player.volume.value = volume;
    this.setState({ volume: volume });
  };

  render() {
    return (
      <div className="stemrow">
        <div className="stem">
          <StemLabel
            label={this.props.label}
            subLabel={this.state.audioLabel}
            icon={this.props.icon}
            muted={this.state.muted}
            toggleMute={this.toggleMute}
            solo={this.state.solo}
            toggleSolo={this.toggleSolo}
            changetrack={this.changetrack}
            audio={this.props.audio}
            volume={this.state.volume}
            currentAudioIndex={this.state.currentAudioIndex}
            handleSkipBackward={this.handleSkipBackward}
            handleSkipForward={this.handleSkipForward}
            handleVolumeChange={this.handleVolumeChange}
          />
          <div className="waveform" ref={this.waveformRef}></div>
        </div>
      </div>
    );
  }
}
