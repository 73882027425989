import * as Tone from "tone";

export function handleSkip(
  direction,
  state,
  props,
  player,
  waveform,
  setState
) {
  const audioLength = props.audio.length;
  const newIndex =
    (state.currentAudioIndex + direction + audioLength) % audioLength;
  const newAudio = props.audio[newIndex];
  const currentTime = player.toSeconds(player.transportTime);

  // player.load(newAudio.audio);
  // Stop the player and load the new audio
  player.stop();
  Tone.Buffer.fromUrl(newAudio.audio).then((buffer) => {
    player.buffer.set(buffer);
  });
  waveform.load(newAudio.audio);
  if (state.isPlaying) {
    player.start(currentTime);
    waveform.play(currentTime);
  }
  setState({
    currentAudioIndex: newIndex,
    audioFile: newAudio.audio,
    audioLabel: newAudio.label,
    originalKey: newAudio.key,
    originalMinor: newAudio.minor,
    originalBPM: newAudio.bpm,
  });
}
