// export const drumsData = [
//   {
//     label: "Drum 3",
//     audio: "/assets/audio/drums/Drum Loop 1 - 100 BPM.wav",
//     bpm: 100,
//     key: "C",
//     minor: false,
//   },
//   {
//     label: "Drum 4",
//     audio: "/assets/audio/drums/Drum Loop 2 - 110 BPM.wav",
//     bpm: 110,
//     key: "C",
//     minor: false,
//   },
//   {
//     label: "Drum 5",
//     audio: "/assets/audio/drums/Drum Loop 3 - 130 BPM.wav",
//     bpm: 130,
//     key: "C",
//     minor: false,
//   },
//   {
//     label: "Drum 6",
//     audio: "/assets/audio/drums/Drum Loop 4 - 130 BPM.wav",
//     bpm: 130,
//     key: "C",
//     minor: false,
//   },
//   {
//     label: "Drum 7",
//     audio: "/assets/audio/drums/Drum Loop 5 - 140 BPM.wav",
//     bpm: 140,
//     key: "C",
//     minor: false,
//   },
//   {
//     label: "Drum 8",
//     audio: "/assets/audio/drums/Drum Loop 6 - 140 BPM.wav",
//     bpm: 140,
//     key: "C",
//     minor: false,
//   },
//   {
//     label: "Drum 9",
//     audio: "/assets/audio/drums/Drum Loop 7 - 160 BPM.wav",
//     bpm: 160,
//     key: "C",
//     minor: false,
//   },
//   {
//     label: "Drum 10",
//     audio: "/assets/audio/drums/Drum Loop 8 - 160 BPM.wav",
//     bpm: 160,
//     key: "C",
//     minor: false,
//   },
//   {
//     label: "Drum 11",
//     audio: "/assets/audio/drums/Drum Loop 9 - 170 BPM.wav",
//     bpm: 170,
//     key: "C",
//     minor: false,
//   },
// ];

export const drumsData = [
  {
    label: "Drum 3",
    audio: "/assets/audio/drums/Drum Loop 1 - 100 BPM.wav",
    bpm: 100,
    key: "D#/Eb",
    minor: false,
  },
  {
    label: "Drum 4",
    audio: "/assets/audio/drums/Drum Loop 2 - 110 BPM.wav",
    bpm: 110,
    key: "E",
    minor: false,
  },
  {
    label: "Drum 5",
    audio: "/assets/audio/drums/Drum Loop 3 - 130 BPM.wav",
    bpm: 129,
    key: "C#/Db",
    minor: false,
  },
  {
    label: "Drum 6",
    audio: "/assets/audio/drums/Drum Loop 4 - 130 BPM.wav",
    bpm: 130,
    key: "D#/Eb",
    minor: true,
  },
  {
    label: "Drum 7",
    audio: "/assets/audio/drums/Drum Loop 5 - 140 BPM.wav",
    bpm: 70,
    key: "G",
    minor: true,
  },
  {
    label: "Drum 8",
    audio: "/assets/audio/drums/Drum Loop 6 - 140 BPM.wav",
    bpm: 70,
    key: "G",
    minor: false,
  },
  {
    label: "Drum 9",
    audio: "/assets/audio/drums/Drum Loop 7 - 160 BPM.wav",
    bpm: 80,
    key: "A",
    minor: false,
  },
  {
    label: "Drum 10",
    audio: "/assets/audio/drums/Drum Loop 8 - 160 BPM.wav",
    bpm: 106,
    key: "F#/Gb",
    minor: true,
  },
  {
    label: "Drum 11",
    audio: "/assets/audio/drums/Drum Loop 9 - 170 BPM.wav",
    bpm: 170,
    key: "G#/Ab",
    minor: true,
  },
];
