import React from "react";
import "../css/Buttons.scss";

export function KeyLabel(props) {
  const { chosenKey, handleKeyClick, keyArea, musicalKey } = props;
  const chosen = chosenKey === musicalKey ? "chosen-key" : "";

  return (
    <>
      <button
        className={`${chosen} ${keyArea} key-label`}
        key={chosenKey}
        onClick={() => handleKeyClick(chosenKey)}
      >
        {chosenKey}
      </button>
    </>
  );
}

export function CustomTextLg(text) {
  return (
    <div className="h1text-lg">
      <div className="surname valign-text-middle montserrat-medium-dawn-pink-18px">
        {text}
      </div>
    </div>
  );
}

export function CustomTextM(text) {
  return <div className="h1text-m">{text}</div>;
}

export function CustomButton(props) {
  const { text, handleClick } = props;

  return (
    <>
      <button className={`key-label`} onClick={() => handleClick()}>
        {text}
      </button>
    </>
  );
}
