export const hihatData = [
  {
    label: "HiHat 1",
    audio: "/assets/audio/hihat/Hihat Loop 1 - 128 BPM.wav",
    bpm: 128,
    key: "C",
    minor: false,
  },
  {
    label: "HiHat 2",
    audio: "/assets/audio/hihat/Hihat Loop 2 - 140 BPM.wav",
    bpm: 140,
    key: "C",
    minor: false,
  },
  {
    label: "HiHat 3",
    audio: "/assets/audio/hihat/Hihat Loop 3 - 150 BPM.wav",
    bpm: 150,
    key: "C",
    minor: false,
  },
  {
    label: "HiHat 4",
    audio: "/assets/audio/hihat/DHihat Loop 4 - 160 BPM.wav",
    bpm: 160,
    key: "C",
    minor: false,
  },
  {
    label: "Hihat 5",
    audio: "/assets/audio/hihat/Hihat Loop 5 - 160 BPM.wav",
    bpm: 130,
    key: "C",
    minor: false,
  },
  {
    label: "Hihat 6",
    audio: "/assets/audio/hihat/Hihat Loop 6 - 180 BPM.wav",
    bpm: 140,
    key: "C",
    minor: false,
  },
];

// export const hihatData = [
//   {
//     label: "HiHat 1",
//     audio: "/assets/audio/hihat/Hihat Loop 1 - 128 BPM.wav",
//     bpm: 128,
//     key: "B",
//     minor: false,
//   },
//   {
//     label: "HiHat 2",
//     audio: "/assets/audio/hihat/Hihat Loop 2 - 140 BPM.wav",
//     bpm: 70,
//     key: "G",
//     minor: true,
//   },
//   {
//     label: "HiHat 3",
//     audio: "/assets/audio/hihat/Hihat Loop 3 - 150 BPM.wav",
//     bpm: 75,
//     key: "B",
//     minor: true,
//   },
//   {
//     label: "HiHat 4",
//     audio: "/assets/audio/hihat/DHihat Loop 4 - 160 BPM.wav",
//     bpm: 80,
//     key: "G#/Ab",
//     minor: true,
//   },
//   {
//     label: "Hihat 5",
//     audio: "/assets/audio/hihat/Hihat Loop 5 - 160 BPM.wav",
//     bpm: 80,
//     key: "A#/Bb",
//     minor: true,
//   },
//   {
//     label: "Hihat 6",
//     audio: "/assets/audio/hihat/Hihat Loop 6 - 180 BPM.wav",
//     bpm: 90,
//     key: "A",
//     minor: true,
//   },
// ];
