import WaveSurfer from "wavesurfer.js";

export function WaveSurferCreator(currentRef) {
  return WaveSurfer.create({
    container: currentRef,
    waveColor: "#dcdcdc",
    progressColor: "#FF9500",
    responsive: true,
    height: 100,
    barWidth: 2,
    barHeight: 1,
    barGap: null,
    scrollParent: true,
  });
}
