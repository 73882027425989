import { keyDict } from "../../data/KeyData";
import * as Tone from "tone";

export function calculateSemitoneShift(startKey, startMinor, endKey, endMinor) {
  console.log(
    startKey + " " + minor(startMinor) + " to " + endKey + " " + minor(endMinor)
  );
  const getSemitones = (key, minor) => {
    const semitones = keyDict[key] || 0;
    const shift = minor ? 3 : 0;
    return semitones + shift;
  };

  const startSemitones = getSemitones(startKey, startMinor);
  const endSemitones = getSemitones(endKey, endMinor);
  const semitoneShift = endSemitones - startSemitones;
  return semitoneShift;
}

export function minor(minor) {
  return minor ? "minor" : "major";
}

export function setPitchShift(amount, player, pitchShiftObj) {
  pitchShiftObj.pitch = amount; // update the pitchShift's pitch property
  if (player.state === "started") {
    player.stop();
    player.disconnect();
    player.connect(pitchShiftObj);
    player.start();
  } else {
    player.disconnect();
    player.connect(pitchShiftObj);
  }
}

export function toggleMute(muted, player, setMuted) {
  const newMuted = !muted;
  player.mute = newMuted;
  setMuted(newMuted);
}

export function keyName(key, minor) {
  return key + " " + minor(minor);
}

export function keyLogConsole(previous, now, amount) {
  console.log(
    "Key changed from : " + previous + " to " + now + " by " + amount
  );
}

export function togglePlay(
  isPlaying,
  setPlaying,
  loaded,
  player,
  waveform,
  audioContext,
  audio,
  pitchShiftObj
) {
  if (!loaded) {
    console.log("audio not loaded");
    return;
  }
  if (isPlaying) {
    player.stop();
    waveform.pause();
  } else {
    if (!player) {
      player = new Tone.Player(audio, () => {
        console.log("Player onload callback called");
      });
    }
    if (!pitchShiftObj) {
      pitchShiftObj = new Tone.PitchShift(0).toDestination();
    }
    if (!audioContext.current) {
      audioContext.current = new AudioContext();
      Tone.setContext(audioContext.current);
      audioContext.current.resume();
    }
    if (audioContext.current.state === "suspended") {
      audioContext.current.resume();
    }
    if (!player.state.startsWith("started")) {
      player.start();
    }
    waveform.play();
  }
  setPlaying(!isPlaying);
}
