import { calculateSemitoneShift } from "../Key/KeyFunctions";
import { updateBPM } from "../BPM/BPMFunctions";

export const updatePlaybackSettings = (state, pitchShift, player, waveform) => {
  const pitchShiftValue = calculateSemitoneShift(
    state.originalKey,
    state.originalMinor,
    state.musicalKey,
    state.minor
  );

  pitchShift.set({ pitch: pitchShiftValue });
  updateBPM(state.originalBPM, state.bpm, player, waveform, pitchShift);
};
