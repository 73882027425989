import "../../css/BPM.scss";

export function SpeedLabel() {
  return (
    <div className="montserrat-medium-dawn-pink-16px valign-text-middle">
      Speed
    </div>
  );
}

export function BPMLabel(id) {
  return (
    <label
      className="montserrat-medium-dawn-pink-12px valign-text-middle"
      htmlFor={id}
    >
      BPM
    </label>
  );
}

export function DataList(props) {
  return (
    <div className="speed-grid-root">
      <input
        type="range"
        id="slider"
        name="slider"
        min="55"
        max="175"
        value={props.value}
        onChange={props.handleChange}
        onMouseUp={props.handleMouseUp}
        list="tickmarks"
      />
      <datalist
        id="tickmarks"
        className="tickmarks h1text-lg montserrat-medium-dawn-pink-12px"
      >
        <option value="55" label="Slower" className="tick-options" />
        <option value="175" label="Faster" className="tick-options" />
      </datalist>
    </div>
  );
}

export function BPMInputSection(props) {
  return (
    <div className="bpm-input-label">
      {BPMLabel("bpm-input")}
      <input
        id="bpm-input"
        className="bpm-input montserrat-medium-dawn-pink-12px"
        type="number"
        value={props.value}
        onChange={props.handleChange}
        onBlur={props.handleMouseUp}
        step="1"
      />
    </div>
  );
}
