import { bassData } from "./Bass";
import { drumsData } from "./Drums";
import { hihatData } from "./HiHat";

const stemData = [
  {
    icon: "/assets/images/drums.svg",
    label: "Drums",
    audio: drumsData,
    muted: false,
    solo: false,
  },
  // {
  //   icon: "/assets/images/bass.svg",
  //   label: "Bass",
  //   audio: bassData,
  //   muted: false,
  //   solo: false,
  // },
  {
    icon: "/assets/images/bass.svg",
    label: "HiHat",
    audio: hihatData,
    muted: false,
    solo: false,
  },
];

export default stemData;
