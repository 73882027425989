import React from "react";
import { CustomKey } from "./Key/CustomKey";
import { Slider } from "./BPM/BPM";
import "../index.css";

export function CustomizeSection(props) {
  const { musicalKey, minor, bpm, handleKeyClick, handleBPMChange } = props;

  return (
    <div className="divflex">
      <div className="frame-1-1">
        <div className="frame-1-1-1">
          <Slider bpm={bpm} handleBPMChange={handleBPMChange} />
          <CustomKey
            musicalKey={musicalKey}
            minor={minor}
            handleKeyClick={handleKeyClick}
          />
        </div>
      </div>
    </div>
  );
}
