import React, { useState } from "react";
import ToggleKey from "./ToggleKey";
import { KeyLabel } from "../CustomButtons";
import { topKeys, bottomKeys } from "../../data/KeyData";
import "../../css/CustomKey.scss";

export function CustomKey(props) {
  const [isMinor, setIsMinor] = useState(props.minor);
  const [musicalKey, setMusicalKey] = useState(props.musicalKey);
  const [showDropdown, setShowDropdown] = useState(false);

  function handleKeyClick(key) {
    setMusicalKey(key);
    props.handleKeyClick(key, isMinor);
  }

  function handleMinorToggle(value) {
    setIsMinor(value);
    props.handleKeyClick(musicalKey, value);
  }

  function renderKey(key, keyArea) {
    return (
      <KeyLabel
        key={key}
        chosenKey={key}
        label={key}
        musicalKey={musicalKey}
        keyArea={keyArea}
        handleKeyClick={() => handleKeyClick(key)}
      />
    );
  }

  function renderKeys() {
    if (showDropdown) {
      return (
        <select
          value={musicalKey}
          onChange={(event) => handleKeyClick(event.target.value)}
        >
          {topKeys.map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
          {bottomKeys.map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <div className="key-set">
          <div className="topKeys">
            {topKeys.map((key) => renderKey(key, "key-label-top"))}
          </div>
          <img
            className="svgh2d-c8c9c4aa"
            src="/assets/images/svg-h2d-c8c9c4aa.svg"
            alt=""
          />
          <div className="bottomKeys">
            {bottomKeys.map((key) => renderKey(key, "key-label-bottom"))}
          </div>
        </div>
      );
    }
  }

  function handleResize() {
    if (window.innerWidth < 768) {
      // Change 768 to the desired breakpoint
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  }

  // Add event listener to listen for window resize
  window.addEventListener("resize", handleResize);

  return (
    <div className="divjss519">
      <ToggleKey minor={isMinor} onToggle={handleMinorToggle} />
      {renderKeys()}
    </div>
  );
}
