// import * as Tone from "tone";
import React from "react";
import { WaveSurferCreator } from "./WaveForm/WaveForm.js";
import "../css/Track.scss";
import { FaPlay, FaPause } from "react-icons/fa";

export default class Track extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
    };
    this.waveformRef = React.createRef();
    this.waveform = null;
  }
  componentDidMount() {
    this.waveform = WaveSurferCreator(this.waveformRef.current);
    this.waveform.load(this.props.audio);
    this.waveform.setMute(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPlaying !== this.props.isPlaying) {
      this.setState({ isPlaying: this.props.isPlaying });
      this.waveform.playPause();
    }
  }

  componentWillUnmount() {
    if (this.waveform) {
      this.waveform.destroy();
    }
  }

  togglePlay = () => {
    this.setState({ isPlaying: !this.state.isPlaying });
    this.props.togglePlay();
  };

  render() {
    return (
      <div className="divbg-black">
        <button className="divflex-2" onClick={this.togglePlay}>
          {this.state.isPlaying ? (
            <FaPause className="icon" />
          ) : (
            <FaPlay className="icon" />
          )}
        </button>
        <div className="divflex-1">
          <div className="divw-full-1">
            <div className="waveform" ref={this.waveformRef}></div>
          </div>
        </div>
      </div>
    );
  }
}
